@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Noto+Sans+JP:wght@100&display=swap');

:root {
  --dark-bg: #131721;
  --light-bg: white;
}

body {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.custom-body {
  font-family: 'IBM Plex Mono', monospace;
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 1.2rem;
  min-height: 100vh;
}

.custom-body .custom-input {
  border: 1px solid black;
  color: white;
  border-radius: 0;
  background-color: #2e3344;
}

.custom-body .custom-input:focus {
  color: white;
  background-color: #2e3344;
}

.custom-body .custom-input::placeholder {
  color: rgb(119, 119, 119);
}

.custom-body .custom-col {
  margin-bottom: 15px;
}

.custom-body .custom-row {
  padding :20px 0 40px 0;
}

.custom-body span {
  overflow-wrap: anywhere;
}

.custom-body .print-core-section {
  margin-bottom: 20px;
  margin-top: 5px;
}

.main-container {
  max-width: 960px;
}

.theme-switch {
  float: right;
  padding: 8px 8px;
  cursor: pointer;
  user-select: none;
}

/* Dark Theme */

.body-dark {
  color: white;
  background-color: var(--dark-bg);
}

.body-dark .span-title {
  color: #8957e5;
  display: inline-block
}

.body-dark .span-section {
  /* color: #3fb950 */
  color: #68ff00;
  display: block;
}

.body-dark .span-section span {
  color: white
}

.body-dark .span-block-section {
  color: #f8c555;
}

.body-dark .theme-switch {
  color: white;
}